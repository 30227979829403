import models from "models";

export interface LoginCredentials {
  username: string;
  password: string;
  code?: string;
}
export interface CodeConfirmation {
  username: string;
  redirect?: string;
  type?: typeof CodeType;
}

export enum CodeType {
  RESET_PASSWORD = "reset_password",
  CONFIRM_ACCOUNT = "confirm_account",
  SIGN_IN_ACCOUNT = "sign_in_account",
}

export interface RegisterCredentials {
  first_name?: string;
  last_name?: string;
  username?: string;
  password?: string;
  profile?: models.User;
  token?: string;
  redirect_url?: string;
}

export interface RegisterConfirmCredentials {
  redirect?: string;
  code?: string;
  username?: string;
  token?: string;
}

export interface TokensResponse {
  access?: string;
  refresh?: string;
}

export interface ResponseWithSuccess {
  success?: string;
  message?: string;
}
export interface ResetPassword {
  username: string;
  redirect?: string;
}
export interface ConfirmResetPassword {
  code: string;
  password: string;
  confirm_password: string;
  username?: string;
}
