import $axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "app-constants";

import { authApi } from "api/auth";
import i18n from "translation/i18n";

export const cancelInterceptors = [
  (response: AxiosResponse) => response,
  (error: any) => {
    if ($axios.isCancel(error)) {
      return Promise.reject({
        cancel: true,
        message: "The endpoint was cancelled",
      });
    }

    if (error.response) {
      // handle errors
    }

    return Promise.reject(error);
  },
];

export const authInterceptor = (config: AxiosRequestConfig) => {
  const authToken = localStorage.getItem(ACCESS_TOKEN_KEY);

  if (!config.headers) config.headers = {};
  if (authToken) config.headers["Authorization"] = `Bearer ${authToken}`;

  return config;
};

/**
 *
 * @param failCallback Called when token is not valid and fails to refresh
 */
export const createRefreshTokenInterceptors = (
  axios: AxiosInstance,
  failCallback: () => void,
) => [
  //TODO: Add type instead of any
  (res: any) => res,
  async (err: any) => {
    const originalConfig = err.config;

    if (err.response.data.code === "token_not_valid") {
      // Access Token was expired or invalid
      if (!originalConfig._retry) {
        originalConfig._retry = true;
        const resfreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
        if (resfreshToken)
          try {
            await authApi.refresh(resfreshToken);

            return axios(originalConfig);
          } catch (_error: any) {
            const error = _error as AxiosError;
            if (error.response && error.response.data) {
              failCallback();
              return Promise.reject(error.response.data);
            }

            return Promise.reject(error);
          }
      }
    }

    return Promise.reject(err);
  },
];

export const languageInterceptor = (config: AxiosRequestConfig) => {
  const language = i18n.resolvedLanguage;

  if (language) {
    if (!config.headers) config.headers = {};
    config.headers["Accept-Language"] = language;
  }

  return config;
};
