import models from "models";

export const NO_AVAILABLE_ERRORS_MESSAGE =
  "An unexpected error occurred, please contact support.";

//Auth constants
export const REFRESH_TOKEN_KEY = "refresh_token";
export const ACCESS_TOKEN_KEY = "access_token";
export const API_POST_CONTENT_TYPE = "application/json";

export const MAX_DOCUMENT_UPLOAD_SIZE_2 = 2_097_152; // 2 MB
export const MAX_DOCUMENT_UPLOAD_SIZE_5 = 5_242_880; // 5 MB
export const MAX_DOCUMENT_UPLOAD_SIZE_10 = 10_485_760; // 10 MB
export const MAX_DOCUMENT_UPLOAD_SIZE_50 = 52_428_800; // 50 MB
export const RECOMMENDED_RESOLUTION_1000_1000 = "1000x1000";
export const RECOMMENDED_RESOLUTION_2000_1500 = "2000x1500";
export const ACCEPTED_IMAGE_EXTENSIONS = ["jpg", "jpeg", "png", "gif"];

export const TILE_LAYER_CONFIG = {
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
};

export const CURRENCY_CODE = {
  EUR: "EUR",
  USD: "USD",
  GBP: "GBP",
} as const;
export type CURRENCY_CODE_TYPE = typeof CURRENCY_CODE;
export const CURRENCY_CODE_FALLBACK = CURRENCY_CODE.USD;

export const CURRENCY_SYMBOL = {
  EUR: "€",
  USD: "$",
  GBP: "£",
} as const;
export type CURRENCY_SYMBOL_TYPE = typeof CURRENCY_SYMBOL;
export const CURRENCY_SYMBOL_FALLBACK = CURRENCY_SYMBOL.USD;

export const DAYS_OF_THE_WEEK: {
  name: models.DayOfTheWeek;
  shortName: string;
}[] = [
  {
    name: "Monday",
    shortName: "Mon",
  },
  {
    name: "Tuesday",
    shortName: "Tue",
  },
  {
    name: "Wednesday",
    shortName: "Wed",
  },
  {
    name: "Thursday",
    shortName: "Thu",
  },
  {
    name: "Friday",
    shortName: "Fri",
  },
  {
    name: "Saturday",
    shortName: "Sat",
  },
  {
    name: "Sunday",
    shortName: "Sun",
  },
];

export const MAP_CONTAINER_DEFAULT_CONFIG = {
  //LONDON
  lat: 51.507,
  lng: -0.136,
};

export const WAREHOUSE_STATE_OPTIONS = [
  {
    key: "WH_OK",
    value: "Warehouse OK",
  },
  {
    key: "WH_REJECTED",
    value: "Warehouse Rejected",
  },
  {
    key: "READY",
    value: "Ready",
  },
  {
    key: "REMOVED",
    value: "Removed",
  },
];

export const USER_ROLES = {
  MAIN_ADMINISTRATOR: "Main Administrator",
  ADMINISTRATOR: "Administrator",
  WAREHOUSE_OPERATOR: "Warehouse Operator",
  WAREHOUSE_SUPERVISOR: "Warehouse Supervisor",
};

export const ADMIN_ROLES = {
  MAIN_ADMINISTRATOR: USER_ROLES.MAIN_ADMINISTRATOR,
  ADMINISTRATOR: USER_ROLES.ADMINISTRATOR,
};

export const WAREHOUSE_ROLES = {
  WAREHOUSE_OPERATOR: USER_ROLES.WAREHOUSE_OPERATOR,
  WAREHOUSE_SUPERVISOR: USER_ROLES.WAREHOUSE_SUPERVISOR,
};

export const isActiveStatus = {
  true: "Active",
  false: "Blocked",
};
export const isDisplayedStatus = {
  true: "Active",
  false: "Inactive",
};

export const isOnlineStatus = {
  true: "Online",
  false: "Offline",
};

export const MINUTE = 60;

export const CURRENT_LOCALE = {
  en: "en-UK",
  de: "de-DE",
  it: "it-IT",
} as const;

export const DATE_FORMAT = {
  "en-UK": "dd/MM/yyyy",
  "de-DE": "dd.MM.yyyy",
  "it-IT": "dd/MM/yyyy",
};

export type CURRENT_LOCALE_KEY = keyof typeof CURRENT_LOCALE;

export const COUNTRY_LIST_LIMIT = 300;
export const CITY_LIST_LIMIT = 1000;
export const NO_VALUE_FALLBACK = "---";
export const DEFAULT_LANGUAGE = "en";
