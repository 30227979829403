import $axios, { AxiosInstance } from "axios";

import { API_POST_CONTENT_TYPE } from "app-constants";

import {
  authInterceptor,
  cancelInterceptors,
  languageInterceptor,
} from "./interceptors";
import { getXAPIKey } from "./utils";

export const apiAxios = $axios.create();

attachApiAxiosConfig(apiAxios);

apiAxios.interceptors.request.use(languageInterceptor);

export function attachApiAxiosConfig(apiAxios: AxiosInstance) {
  if (!apiAxios.defaults.headers.common) apiAxios.defaults.headers.common = {};
  apiAxios.defaults.headers.common["Content-Type"] = API_POST_CONTENT_TYPE;

  const apiKey = getXAPIKey();
  if (apiKey) apiAxios.defaults.headers.common["X-API-Key"] = apiKey;

  apiAxios.defaults.baseURL = process.env.REACT_APP_BASE_API;

  apiAxios.interceptors.request.use(authInterceptor);
  apiAxios.interceptors.response.use(...cancelInterceptors);
}
